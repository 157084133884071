import React from 'react';
import { useTranslation } from 'react-i18next';

const Energy = () => {
  const { t } = useTranslation();

  return (
    <div className="energy-container">
      <div className="energy-section">
        <div className="energy-flex-container">
          <div className="energy-card-left">
            <img
              src="https://ml-paysoltech.com/img/aerial-view-private-house-with-solar-panels-roof.jpg"
              alt="Panneaux Solaires"
              className="energy-image"
            />
          </div>
          <div className="energy-card-right">
            <div className="energy-header">
              <img
                src="https://ml-paysoltech.com/img/ml-paysoltech-1.gif"
                alt="ML-PaySolTech Logo"
                className="energy-logo"
              />
              <h2 className="energy-title">{t('energy.title')}</h2>
            </div>
            <p className="energy-text"
              dangerouslySetInnerHTML={{ __html: t('energy.description')} }
          />
            <a href="#contact" className="card-button button1">{t('button.btn2')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Energy;