const fr = {
    introduction:{
        intro : "L'innovation énergétique à portée de main. Nous mettons notre expertise à votre service pour vous offrir des solutions sur mesure, durables et efficaces. Optimisez vos installations et sécurisez vos transactions avec un partenaire de confiance.",
    },
    button:{
        btn1 : "En savoir plus",
        btn2 : "Contactez-nous",
        btn3 : "Envoyer",
    }
    ,energy: {
        title:"Énergies",
        resume: "ML-PaySolTech offre des solutions durables en énergie. Nous optimisons vos systèmes énergétiques et installons des pompes à chaleur, des bornes de recharge, effectuons des travaux d'électricité, ainsi que la vente et l'entretien de chaudières, pour une transition énergétique efficace.",
        description: "Chez ML-PaySolTech, nous mettons notre expertise en énergie au service de solutions durables et efficaces.<br/> <br/>Que ce soit pour l'installation de systèmes énergétiques dans les secteurs domestiques, commerciaux ou industriels, incluant des panneaux photovoltaïques, des pompes à chaleur, des bornes de recharge pour véhicules électriques, ou encore pour des travaux d'électricité et l'entretien de chaudières, chaque projet est conçu pour maximiser l'efficacité énergétique tout en minimisant l'impact environnemental.<br/> <br/>Nous proposons également des solutions de stockage d'énergie de pointe et des options sur mesure pour répondre à des besoins spécifiques, comme l'intégration de technologies solaires dans des véhicules ou des structures existantes. Notre objectif est de vous accompagner vers une transition énergétique réussie et durable."
    },
    payment: {
        title:"Solutions de Paiement",
        resume: "ML-PaySolTech simplifie les transactions avec des solutions de paiement avancées et sécurisées. Intégrez nos terminaux pour une expérience fluide et optimisez vos paiements avec notre expertise.",
        description: "ML-PaySolTech révolutionne la gestion des transactions en proposant des solutions de paiement à la fois avancées et sécurisées.<br/>  Nos terminaux de paiement sont conçus pour s’intégrer parfaitement à vos systèmes existants, garantissant une expérience utilisateur fluide et sans accroc.<br/>  Grâce à nos technologies de pointe, chaque transaction est traitée avec rapidité et fiabilité, vous permettant de vous concentrer sur ce qui compte vraiment : votre activité.<br/> <br/>  En choisissant ML-PaySolTech, vous optez pour une expertise reconnue dans l’optimisation des processus de paiement. Nos solutions sont non seulement efficaces, mais aussi flexibles pour s’adapter aux besoins spécifiques de votre entreprise.<br/> <br/>  Que vous soyez une petite entreprise ou un grand groupe, nous mettons tout en œuvre pour sécuriser vos transactions et améliorer votre performance opérationnelle."
    },
    consultant: {
        title:"Consultance",
        resume: "Optimisez votre consommation énergétique avec ML-PaySolTech. Nous vous offrons des stratégies sur mesure pour réduire vos coûts tout en protégeant l'environnement. Nos experts vous accompagnent de l'analyse à la mise en œuvre, pour transformer vos défis en opportunités durables.",
        description: "Chez ML-PaySolTech, nous savons que la gestion efficace de l’énergie est cruciale pour les entreprises souhaitant réduire leurs coûts tout en contribuant à la protection de l’environnement. <br/> <br/> Notre approche de consultance en énergie vise à fournir aux entreprises des stratégies et outils pour optimiser leur consommation énergétique. Nous commençons par une analyse détaillée de vos pratiques actuelles pour identifier des pistes d’amélioration, qu’il s’agisse de moderniser vos infrastructures ou d’améliorer l’efficacité de vos équipements.<br/> <br/>  Notre équipe d’experts vous accompagne tout au long de la mise en œuvre de ces solutions, en veillant à ce qu’elles soient parfaitement adaptées à vos besoins et objectifs. Chez ML-PaySolTech, nous sommes votre partenaire de confiance pour améliorer votre performance énergétique tout en réduisant votre empreinte écologique."
    },
    contact: {
        title:"Contactez-nous",
        description: "Vous avez des questions ou souhaitez en savoir plus sur nos services ? <br/> <br/> Notre équipe est à votre disposition pour répondre à toutes vos demandes.<br/>  Contactez-nous dès aujourd'hui et découvrez comment ML-PaySolTech peut vous accompagner dans vos projets énergétiques et financiers.",
        name :"Nom",
        email :"Adresse email",
        message :"Message",
        nameError :"est requis!",
        emailError :"n'est pas un email valide!",
        messageError :"n'est pas un numéro valide!",
    },
}
export default fr;