import React from 'react';
import CardBackGround from './CardBackGround';

const CardTop = () => {
  return (
    <div className="full-width-image">
        <div className="overlay">
      <CardBackGround/>
      </div>
    </div>
  );
};

export default CardTop;