import './style/Cards.css';

import './App.css';
import Cards from './components/Cards';
import CardTop from './components/CardTop'

function App() {

  return <div className="cards-container">
    <CardTop/>
    <Cards/>    
  </div>

}

export default App;


