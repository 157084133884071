import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './components/en';
import fr from './components/fr';
import nl from './components/nl';

i18n
  .use(initReactI18next) // Connecte i18next avec react-i18next
  .init({
    resources: {
      nl: { translation: nl },
      fr: { translation: fr },
      en: { translation: en },
    },
    lng: 'fr', // Langue par défaut
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // React se charge déjà de l'échappement
    },
  });

export default i18n;