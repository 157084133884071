const en = {
  introduction:{
      intro : "Bringing energy innovation to your fingertips. We leverage our expertise to provide you with tailored, sustainable, and efficient solutions. Optimize your installations and secure your transactions with a trusted partner.",
  },
  button:{
      btn1 : "Learn more",
      btn2 : "Contact us",
      btn3 : "Send",
  },
  energy: {
      title:"Energy",
      resume: "ML-PaySolTech offers sustainable solutions in renewable energy. We optimize your photovoltaic systems and install charging stations for an effective energy transition.",
      description: "At ML-PaySolTech, we bring our expertise in renewable energy to deliver sustainable and effective solutions.<br/><br/> Whether it’s for the installation of photovoltaic systems in residential, commercial, or industrial sectors, installing electric vehicle charging stations, or replacing faulty inverters and solar panels, each project is designed to maximize energy production while minimizing environmental impact.<br/><br/> We also offer advanced energy storage solutions and custom options to meet specific needs, such as integrating solar technologies into vehicles or existing structures.<br/><br/> Our goal is to guide you towards a successful and sustainable energy transition."
  },
  payment: {
      title:"Payment Solutions",
      resume: "ML-PaySolTech simplifies transactions with advanced and secure payment solutions. Integrate our terminals for a smooth experience and optimize your payments with our expertise.",
      description: "ML-PaySolTech is revolutionizing transaction management by offering advanced and secure payment solutions.<br/><br/> Our payment terminals are designed to seamlessly integrate with your existing systems, ensuring a smooth and flawless user experience.<br/><br/> With our cutting-edge technology, each transaction is processed quickly and reliably, allowing you to focus on what really matters: your business.<br/><br/> By choosing ML-PaySolTech, you are opting for recognized expertise in payment process optimization. Our solutions are not only effective but also flexible to adapt to the specific needs of your business.<br/><br/> Whether you are a small business or a large corporation, we strive to secure your transactions and improve your operational performance."
  },
  consultant: {
      title:"Consultancy",
      resume: "Optimize your energy consumption with ML-PaySolTech. We offer customized strategies to reduce your costs while protecting the environment. Our experts support you from analysis to implementation, turning your challenges into sustainable opportunities.",
      description: "At ML-PaySolTech, we understand that efficient energy management is crucial for companies looking to reduce costs while contributing to environmental protection.<br/><br/> Our energy consultancy approach aims to provide companies with strategies and tools to optimize their energy consumption. We start with a detailed analysis of your current practices to identify improvement areas, whether it's modernizing your infrastructure or enhancing the efficiency of your equipment.<br/><br/> Our team of experts supports you throughout the implementation of these solutions, ensuring they are perfectly tailored to your needs and objectives. At ML-PaySolTech, we are your trusted partner in improving your energy performance while reducing your environmental footprint."
  },
  contact: {
      title:"Contact Us",
      description: "Do you have questions or want to learn more about our services?<br/><br/> Our team is here to answer all your inquiries.<br/><br/> Contact us today and discover how ML-PaySolTech can assist you with your energy and financial projects.",
      name :"Name",
      email :"Email Address",
      message :"Message",
      nameError :"is required!",
      emailError :"is not a valid email!",
      messageError :"is not a valid number!",
  },
}
export default en;