import React from 'react';
import i18n from '../lang/i18n'; // Correct path to i18n
import { useTranslation } from 'react-i18next';


const CardBackGround = () => {
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="energy-container_">
      <div className="overlay-text">
        <img src="https://ml-paysoltech.com/img/ml-paysoltech-4.png" alt="ML-PaySolTech Logo" />
      </div>
      <div className="overlay-text_">
        {t('introduction.intro')}
      </div>
      <div className="language-buttons">
        <button onClick={() => changeLanguage('fr')}>Français</button>
        <button onClick={() => changeLanguage('nl')}>Nederlands</button>
        <button onClick={() => changeLanguage('en')}>English</button>
      </div>
    </div>
  );
};

export default CardBackGround;