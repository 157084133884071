import React from 'react';
import Energy from './Energy';
import Consultation from './Consultation';
import PaySolution from './PaySolution';
import Contact from './Contact';
import { useTranslation } from 'react-i18next';

const Cards = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="cards-container">
        <div className="card" id="card1">
          <h2 className="card-title">{t('energy.title')}</h2>
          <img src="https://ml-paysoltech.com/img/ml-paysoltech-1.gif" alt="Énergies Renouvelables" className="card-image" />
          <p className="card-description">
          {t('energy.resume')}
          </p>
          <div className="button-container">
            <a href="#energy" className="card-button button1">{t('button.btn1')}</a>
          </div>
        </div>
        <div className="card" id="card2">
          <h2 className="card-title">{t('payment.title')}</h2>
          <img src="https://ml-paysoltech.com/img/ml-paysoltech-2.gif" alt="Solutions de Paiement" className="card-image" />
          <p className="card-description">
          {t('payment.resume')}
          </p>
          <div className="button-container">
            <a href="#paysolution" className="card-button button2">{t('button.btn1')}</a>
          </div>
        </div>
        <div className="card" id="card3">
          <h2 className="card-title">{t('consultant.title')}</h2>
          <img src="https://ml-paysoltech.com/img/ml-paysoltech-3.gif" alt="Consultance Panneaux Solaires et Paiement" className="card-image" />
          <p className="card-description">
          {t('consultant.resume')}
          </p>
          <div className="button-container">
            <a href="#consultance" className="card-button button3">{t('button.btn1')}</a>
          </div>
        </div>
      </div>
      <div id="energy">
        <Energy />
      </div>
      <div id="paysolution">
        <PaySolution />
      </div>
      <div id="consultance">
        <Consultation />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </>
  );
};

export default Cards;