const nl = {
  introduction:{
      intro : "Energie-innovatie binnen handbereik. We zetten onze expertise in om u op maat gemaakte, duurzame en efficiënte oplossingen te bieden. Optimaliseer uw installaties en beveilig uw transacties met een vertrouwde partner.",
  },
  button:{
      btn1 : "Meer weten",
      btn2 : "Contacteer ons",
      btn3 : "Verzenden",
  },
  energy: {
      title:"Energie",
      resume: "ML-PaySolTech biedt duurzame oplossingen op het gebied van hernieuwbare energie. We optimaliseren uw fotovoltaïsche systemen en installeren oplaadpunten voor een efficiënte energietransitie.",
      description: "Bij ML-PaySolTech zetten we onze expertise in hernieuwbare energie in om duurzame en effectieve oplossingen te leveren.<br/><br/> Of het nu gaat om de installatie van fotovoltaïsche systemen in residentiële, commerciële of industriële sectoren, het installeren van oplaadpunten voor elektrische voertuigen, of het vervangen van defecte omvormers en zonnepanelen, elk project is ontworpen om de energieproductie te maximaliseren en tegelijkertijd de milieu-impact te minimaliseren.<br/><br/> We bieden ook geavanceerde energieopslagsystemen en maatwerkopties om aan specifieke behoeften te voldoen, zoals de integratie van zonne-energie in voertuigen of bestaande structuren.<br/><br/> Ons doel is u te begeleiden naar een succesvolle en duurzame energietransitie."
  },
  payment: {
      title:"Betalingsoplossingen",
      resume: "ML-PaySolTech vereenvoudigt transacties met geavanceerde en veilige betalingsoplossingen. Integreer onze terminals voor een soepele ervaring en optimaliseer uw betalingen met onze expertise.",
      description: "ML-PaySolTech revolutioneert het beheer van transacties door geavanceerde en veilige betalingsoplossingen aan te bieden.<br/><br/> Onze betaalterminals zijn ontworpen om naadloos te integreren met uw bestaande systemen, waardoor een soepele en foutloze gebruikerservaring wordt gegarandeerd.<br/><br/> Met onze geavanceerde technologie wordt elke transactie snel en betrouwbaar verwerkt, zodat u zich kunt concentreren op wat echt belangrijk is: uw bedrijf.<br/><br/> Door te kiezen voor ML-PaySolTech kiest u voor erkende expertise in het optimaliseren van betalingsprocessen. Onze oplossingen zijn niet alleen effectief, maar ook flexibel om zich aan te passen aan de specifieke behoeften van uw bedrijf.<br/><br/> Of u nu een klein bedrijf of een grote onderneming bent, wij zetten alles in het werk om uw transacties te beveiligen en uw operationele prestaties te verbeteren."
  },
  consultant: {
      title:"Consultancy",
      resume: "Optimaliseer uw energieverbruik met ML-PaySolTech. We bieden op maat gemaakte strategieën om uw kosten te verlagen en tegelijkertijd het milieu te beschermen. Onze experts begeleiden u van analyse tot implementatie, zodat u uw uitdagingen kunt omzetten in duurzame kansen.",
      description: "Bij ML-PaySolTech weten we dat efficiënt energiebeheer cruciaal is voor bedrijven die kosten willen verlagen en tegelijkertijd willen bijdragen aan milieubescherming.<br/><br/> Onze energieadviesaanpak is gericht op het bieden van strategieën en tools aan bedrijven om hun energieverbruik te optimaliseren. We beginnen met een gedetailleerde analyse van uw huidige praktijken om verbeterpunten te identificeren, of het nu gaat om het moderniseren van uw infrastructuur of het verbeteren van de efficiëntie van uw apparatuur.<br/><br/> Ons team van experts begeleidt u gedurende de hele implementatie van deze oplossingen, waarbij we ervoor zorgen dat ze perfect zijn afgestemd op uw behoeften en doelen. Bij ML-PaySolTech zijn we uw vertrouwde partner in het verbeteren van uw energieprestaties en het verkleinen van uw ecologische voetafdruk."
  },
  contact: {
      title:"Neem Contact Met Ons Op",
      description: "Heeft u vragen of wilt u meer weten over onze diensten?<br/><br/> Ons team staat klaar om al uw vragen te beantwoorden.<br/><br/> Neem vandaag nog contact met ons op en ontdek hoe ML-PaySolTech u kan ondersteunen bij uw energie- en financiële projecten.",
      name :"Naam",
      email :"E-mailadres",
      message :"Bericht",
      nameError :"is verplicht!",
      emailError :"is geen geldig e-mailadres!",
      messageError :"is geen geldig nummer!",
  },
}
export default nl;