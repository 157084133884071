import React, { useState } from 'react';
import { Button, Form, Input, Spin, message } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const getFieldLabel = (label) => {
  // Séparer les différentes parties du nom de champ (par exemple "user.name")
  const parts = label.split('.');
  // Retourner la dernière partie (par exemple "name")
  return parts[parts.length - 1];
};

const validateMessages = {
  required: (label) => `${getFieldLabel(label)} est requis!`,
  types: {
    email: (label) => `${getFieldLabel(label)} n'est pas un email valide!`,
    number: (label) => `${getFieldLabel(label)} n'est pas un numéro valide!`,
  },
  number: {
    range: (label, min, max) => `${getFieldLabel(label)} doit être entre ${min} et ${max}`,
  },
};

const Contact = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true); // Activer le Spin

    try {
      const response = await axios.post('https://ml-paysoltech.com/public_html/index.php/api/sendForm', values);
      if (response.data.success) {
        message.success(response.data.message);
        form.resetFields(); // Vider le formulaire
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error('Erreur lors de l\'envoi du formulaire: ' + error.message);
    } finally {
      setLoading(false); // Désactiver le Spin dans tous les cas
    }
  };

  return (
    <div className="energy-container">
      <div className="contact-section">
        <div className="contact-flex-container">
          <div className="contact-card-left">
            <div className="contact-header">
              <h2 className="contact-title">{t('contact.title')}</h2>
            </div>
            <p className="contact-text"
              dangerouslySetInnerHTML={{ __html: t('contact.description') }}
          />
          </div>
          <div className="contact-card-right">
            <Spin spinning={loading}>
              <Form
              
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                layout="vertical" // Modifier le layout pour que les champs prennent toute la largeur
              >
                <Form.Item
                  name={['user', 'name']}
                  label="Nom"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input  styles={{width:'100%'}}/>
                </Form.Item>
                <Form.Item
                  name={['user', 'email']}
                  label="Email"
                  rules={[
                    {
                      type: 'email',
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name={['user', 'message']} label="Message" rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <Input.TextArea />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={loading}>
                  {t('button.btn3')}
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;