import React from 'react';
import { useTranslation } from 'react-i18next';

const PaySolution = () => {
  const { t } = useTranslation();
  return (
    <div className="energy-container">

    <div className="payment-section">
      <div className="payment-flex-container">
        <div className="payment-card-left">
          <div className="payment-header">
            <img 
              src="https://ml-paysoltech.com/img/ml-paysoltech-2.gif" 
              alt="ML-PaySolTech Logo" 
              className="payment-logo"
            />
            <h2 className="payment-title">{t('payment.title')}</h2>
          </div>
          <p className="payment-text"
              dangerouslySetInnerHTML={{ __html: t('payment.description') }}
          />
          <a href="#contact" className="card-button button2">{t('button.btn2')}</a>
        </div>
        <div className="payment-card-right">
          <img 
            src="https://ml-paysoltech.com/img/pay-bill-restaurant.jpg"
            alt="Solutions de Paiement"
            className="payment-image"
          />
        </div>
      </div>
    </div>
    </div>
  );
};

export default PaySolution;