import React from 'react';
import { useTranslation } from 'react-i18next';

const Consultation = () => {
  const { t } = useTranslation();
  return (
    <div className="energy-container">

    <div className="consultation-section">
      <div className="consultation-flex-container">
        <div className="consultation-card-left">
          <img
            src="https://ml-paysoltech.com/img/paperwork-meeting.jpg"
            alt="Consultation"
            className="consultation-image"
          />
        </div>
        <div className="consultation-card-right">
          <div className="consultation-header">
            <img
              src="https://ml-paysoltech.com/img/ml-paysoltech-3.gif"
              alt="ML-PaySolTech Logo"
              className="consultation-logo"
            />
            <h2 className="consultation-title">{t('consultant.title')}</h2>
          </div>
          <p className="consultation-text"
              dangerouslySetInnerHTML={{ __html: t('consultant.description') }}
          />
          <a href="#contact" className="card-button button3">{t('button.btn2')}</a>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Consultation;